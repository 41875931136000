<template>
  <div>
    <div class="content-tab border-top-line border-bottom-line">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col cols="12">
            <div class="card-content">
              <div class="display-flex align-items-center pa-4">
                <p class="text-name mon-medium">{{ texts.profile.form.textName }}</p>
                <v-spacer />
                <!-- <p class="text-value mon-regular">{{ name }}</p> -->
                <p
                  class="text-value mon-regular mb-0"
                  v-html="(name == '') ? epmtyText : name"
                />
              </div>
              <div class="border-bottom" />
              <div class="display-flex align-items-center pa-4">
                <p class="text-name mon-medium">{{ texts.profile.form.textLastName }}</p>
                <v-spacer />
                <!-- <p class="text-value mon-regular">{{ lastName }}</p> -->
                <p
                  class="text-value mon-regular mb-0"
                  v-html="(lastName == '') ? epmtyText : lastName"
                />
              </div>
              <div class="border-bottom" />
              <div class="display-flex align-items-center pa-4">
                <p class="text-name mon-medium">{{ texts.profile.form.textEmail }}</p>
                <v-spacer />
                <!-- <p class="text-value mon-regular">{{ email }}</p> -->
                <p
                  class="text-value mon-regular mb-0"
                  v-html="(email == '') ? epmtyText : email"
                />
              </div>
              <div class="border-bottom" />
              <div class="display-flex align-items-center pa-4">
                <p class="text-name mon-medium">{{ texts.profile.form.textPosition }}</p>
                <v-spacer />
                <!-- <p class="text-value mon-regular">{{ position }}</p> -->
                <p
                  class="text-value mon-regular mb-0"
                  v-html="(position == '') ? epmtyText : position"
                />
              </div>
              <div class="border-bottom" />
              <div class="display-flex align-items-center pa-4">
                <p class="text-name mon-medium">{{ texts.profile.form.textPhone }}</p>
                <v-spacer />
                <!-- <p class="text-value mon-regular">{{ phone }}</p> -->
                <p
                  class="text-value mon-regular mb-0"
                  v-html="(phone == '') ? epmtyText : phone"
                />
              </div>
              <!-- <div class="border-bottom" /> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div
      v-if="isResponsive"
      class="pt-5 pb-5"
    >
      <v-btn
        @click="openDialogEditProfile"
        class="button-save-changes mon-bold"
        elevation="0"
        style="width: 100%;"
      >
        {{ texts.profile.button.edit }}
      </v-btn>
      <v-btn
        @click="dialogChangePassword"
        class="button-change-password mon-regular mt-5"
        elevation="0"
        style="width: 100%;"
      >
        {{ texts.profile.button.changePassword }}
      </v-btn>
    </div>
    <div
      v-else
      class="display-flex align-items-center pt-5 pb-5"
    >
      <v-btn
        @click="dialogChangePassword"
        class="button-change-password mon-regular"
        elevation="0"
      >
        {{ texts.profile.button.changePassword }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="openDialogEditProfile"
        class="button-save-changes mon-bold"
        elevation="0"
      >
        {{ texts.profile.button.edit }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileTabContentOneLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      aUser: [],
      name: "",
      lastName: "",
      email: "",
      position: "",
      phone: "",
      epmtyText: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getUserData();
    this.validateTextEmpty();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    validateTextEmpty: function () {
      if (this.selectLanguage == "sp") {
        this.epmtyText = "Aún no hay información.";
      } else {
        this.epmtyText = "No infomation yet.";
      }
    },
    openDialogEditProfile: function () {
      this.$store.commit("setDialogEditProfile", {
        active: true,
        arr: this.aUser,
      });
    },
    dialogChangePassword: function () {
      this.$store.commit("setProfileDialogChangePassword", true);
    },
    getUserData: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/employees/${this.$store.state.sUserId}`,
        config
      )
        .then((response) => {
          let arr = response.data.results;
          this.aUser = arr;

          this.name = arr.sName;
          this.lastName = arr.sLastname;
          this.email = arr.sEmail;
          this.position = arr.sPositionName;
          this.phone = arr.sPhoneNumber;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getUserData();
      }
    },
    refresh: function () {
      this.getUserData();
    },
  },
};
</script>

<style scoped>
.text-name {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-value {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.v-input--is-disabled {
  opacity: 0.5;
  cursor: pointer !important;
  pointer-events: all !important;
}

.button-change-password {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: inherit;
  height: 40px !important;
  background-color: transparent !important;
}

.button-edit {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: inherit;
  height: 40px !important;
  background-color: transparent !important;
}

.button-save-changes {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: inherit;
  height: 40px !important;
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-circle {
  color: #ffffff;
  text-align: left;
  font-size: 100px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px;
}

.circle-border {
  border: 5px solid #ffa67e;
  padding: 3px;
  border-radius: 100%;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-content {
  background: transparent linear-gradient(268deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  padding: 25px;
  width: 230px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-tab {
  padding: 40px 0px;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom-line {
  border-bottom: 1px solid #cecece;
}

@media (max-width: 960px) {
  .center-in-small {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>